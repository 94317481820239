import { createContext, useContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    
    const [token, setToken] = useState(() => localStorage.getItem("admintoken") || null);
    const [loggedUser, setLoggedUser] = useState({});
    const [dashboard, setDashboard] = useState(false);
    console.log("LoggedUser",loggedUser)

    console.log(dashboard,"isDashboard")
    console.log(token,"isDashboard==token")

    const storeTokenInLS = async (serverToken) => {
        localStorage.setItem('token', serverToken);
        setToken(serverToken);
        console.log("Token stored", serverToken);
    };

    const userAuthentication = async () => {
        console.log(dashboard,"isDashboardEnter")
        if (!token) return;

        try {
            console.log(token, "Authenticating user...");
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/fetch-profile`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseData = await response.json();

            if (response.ok && responseData.status === 200) {
                console.log(dashboard,"isDashboard====")
                setLoggedUser(responseData.user || {});
                console.log(dashboard,"isDashboard====loggedUser")
                const permissionss = responseData?.user?.role?.permissions.find(p => p.path === "/dashboard");
                console.log(permissionss?.isVisible,"isDashboard======0");
                  if (permissionss?.isVisible){
                    setDashboard(true);
                    console.log(permissionss?.isVisible,"isDashboard======1");
                  } else {
                    setDashboard(false);
                  }
            } else {
                setLoggedUser({});
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setLoggedUser({});
        }
    };

    useEffect(() => {
        userAuthentication();

    }, [token]);

    return (
        <>
            <AuthContext.Provider value={{ loggedUser, setLoggedUser,dashboard, storeTokenInLS }}>
                {children}
            </AuthContext.Provider>
        </>
    );
};

export const useAuth = () => {
    const authContext = useContext(AuthContext);

    if (!authContext) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return authContext;
};
