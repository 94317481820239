import React, { lazy, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer } from './utils/toast';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import PrivateRoute from './components/login/PrivateRoute';
import { useAuth } from 'auth';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const Page404 = lazy(() => import("../src/pages/404"));


//
const Layout = lazy(() => import('./layout/Layout'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const ForgetPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

// routes.forEach(route => {
//   console.log(`Route: ${route.path}`);
//   // Additional metadata can be printed here if you add it to the route objects
// });  

const TestComponent = () => {
   const [canExport, setCanExport] = useState(false);
   const history = useHistory()
   const pathLocation = useLocation()
    const {loggedUser}= useAuth()
     console.log(loggedUser,"jai mata di");
     useEffect(() => {
       const path = pathLocation.pathname; // Get current path
       if (loggedUser && loggedUser.role && loggedUser.role.permissions) {
         const permissions = loggedUser.role.permissions.find(p => p.path === path);
         console.log(permissions,path,"historyhistory");
         if (permissions && permissions.isVisible==false) {
        
          history.push({pathname:'/404'})
        
         }
         
       }
     }, [loggedUser, pathLocation.pathname]);
   
};
const App = () => {

  return (
    <>
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          {/* <Route path="/signup" component={SignUp} />
         
           */}

          <PrivateRoute>
            <Route path="/" component={Layout} />
          </PrivateRoute>
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  );
};

export default App;
