import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "rc-tree/assets/index.css";
import "@pathofdev/react-tag-input/build/index.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Windmill } from "@windmill/react-ui";
import "./assets/css/custom.css";
import "./assets/css/tailwind.css";
import "./assets/css/tailwind.output.css";
import App from "./App";
import myTheme from "./assets/theme/myTheme";
import { AdminProvider } from "./context/AdminContext";
import { SidebarProvider } from "./context/SidebarContext";
import ThemeSuspense from "./components/theme/ThemeSuspense";
import { Provider } from "react-redux";
import store from "./redux/Store";
import { AuthProvider } from "./auth";
import "./i18n";

ReactDOM.render(
  <AuthProvider> {/* Wrap the entire app in AuthProvider */}
    <AdminProvider>
      <SidebarProvider>
        <Provider store={store}>
          <Suspense fallback={<ThemeSuspense />}>
            <Windmill usePreferences theme={myTheme}>
              <App />
            </Windmill>
          </Suspense>
        </Provider>
      </SidebarProvider>
    </AdminProvider>
  </AuthProvider>,

  document.getElementById("root")
);
